import React from 'react';
import BlockContent from '@sanity/block-content-to-react';

const serializers = {
  marks: {
    link: ({ children, mark: { url, targetBlank } }) => {
      let linkProps = {
        href: url,
      };

      if (targetBlank) {
        linkProps = {
          ...linkProps,
          target: '_blank',
          rel: 'noopener noreferrer',
        };
      }

      return <a {...linkProps}>{children[0]}</a>;
    },
  },
  types: {
    block: props => {
      const { style = 'normal' } = props.node;

      if (style === 'credits') {
        return <p className="credits">{props.children}</p>;
      }

      // Fall back to default handling
      return BlockContent.defaultSerializers.types.block(props);
    },
    // atomImageSet: ({ node: { images } }) => <InlineImageSet images={images} />,
  },
};

export default ({ blocks, ...props }) => {
  return <BlockContent blocks={blocks} serializers={serializers} {...props} />;
};
