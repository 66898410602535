import theme from '@/styles/theme';

export const LOCATIONS = {
  nyc: {
    short: 'NYC',
    long: 'New York City',
  },
  par: {
    short: 'PAR',
    long: 'Paris',
  },
  cph: {
    short: 'CPH',
    long: 'Copenhagen',
  },
};

export const SPACING = {
  gridGutter: theme.grid.gutter,
  normal: theme.spacing.medium,
  large: theme.spacing.large,
};
