import React from 'react';
import styled from 'styled-components';

import Text from '@/atoms/Text';
import PortableText from '@/atoms/PortableText';

import { h4 } from '@/styles/typography';
import theme from '@/styles/theme';
import { scale, media } from '@/styles/utils';

const PARAGRAPH_PADDING = {
  '-2': theme.spacing.lhPXS,
  '-1': theme.spacing.lhPS,
  '0': theme.spacing.lhP,
  '1': theme.spacing.lhPL,
  h3: theme.spacing.lhH3,
  h2: theme.spacing.lhH2,
  h1: theme.spacing.lhH1,
};

const RichText = styled(Text)`
  h3 {
    ${h4}
    padding-bottom: ${scale(1)};

    :not(:first-child) {
      padding-top: ${scale(3.5)};

      ${media.lg`
        padding-top: ${scale(6)};
      `}
    }
  }

  p,
  ul,
  ol {
    padding-bottom: ${props => props.paragraphPadding[0]};

    :last-child {
      padding-bottom: 0;
    }

    ${media.sm`
      padding-bottom: ${props => props.paragraphPadding[1]};

      :last-child {
        padding-bottom: 0;
      }
    `}

    ${media.md`
      padding-bottom: ${props => props.paragraphPadding[2]};

      :last-child {
        padding-bottom: 0;
      }
    `}

    ${media.lg`
      padding-bottom: ${props => props.paragraphPadding[3]};

      :last-child {
        padding-bottom: 0;
      }
    `}
  }

  ul {
    list-style: none;

    li {
      position: relative;
      padding-left: ${scale(1.5)};

      :before {
        position: absolute;
        content: '—';
        top: 0;
        left: 0;
      }
    }
  }

  ol {
    margin-left: ${scale(1.25)};

    li {
      padding-left: ${scale(0.25)};
    }
  }

  a {
    transition: color 0.1s ease-out;

    :focus, :hover {
      color: ${props => theme.colors[theme.colorSchemes[props.$color].hover]}
    }
  }

  .credits {
    color: ${theme.colors.gray};
  }
`;

export default ({ blocksRaw, t = 0, $color = 'black', ...props }) => {
  const tString = typeof t === 'number' ? t.toString() : t;

  if (typeof blocksRaw === 'undefined') return null;

  return (
    <RichText
      as="div"
      t={t}
      paragraphPadding={PARAGRAPH_PADDING[tString] || theme.spacing.lhP}
      $color={$color}
      {...props}
    >
      <PortableText blocks={blocksRaw} />
    </RichText>
  );
};
